import { Button, Card, CardCover, Stack, Typography } from '@mui/joy'
import { COLOR, IMAGE } from '../../constants/constants'

const PartenaireZone = () => {
    return (
        <Stack
            justifyContent={"space-between"}
            alignItems={"center"}
            bgcolor={COLOR.darkBlue}
            p={1}
            borderRadius={15}
        >
            <Card
                sx={{
                    width: "100%",
                    p: 0,
                    height: 200,
                }}
            >
                <CardCover>
                    <img src={IMAGE.rangeRoverFooterback} />
                </CardCover>
            </Card>

            <Typography textColor={COLOR.white}>Quel type de voiture avez vous ?</Typography>

            <Stack
                gap={1}
                width={"100%"}
                direction={"row"}
                justifyContent={"space-evenly"}
                sx={{
                    "& button": {
                        width: "100%",
                        height: 80,
                        fontSize: 20,
                        borderRadius: 10
                    }
                }}
            >
                <Button>
                    A vendre
                </Button>
                <Button>
                    A Louer
                </Button>
            </Stack>

        </Stack >
    )
}

export default PartenaireZone