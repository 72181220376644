import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, Stack } from "@mui/joy"
import { COLOR, CONTACT } from "../../constants/constants"
import { faFacebookF, faInstagram, faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const ContactSocialGroup = ({ mt }) => {
    return (
        <Stack
            direction={"row"}
            gap={1}
            mt={mt ?? 2}
        >
            {
                CONTACT?.whatsapp && (
                    <Link
                        href={CONTACT.whatsapp}
                    >
                        <Stack
                            width={40}
                            height={40}
                            bgcolor={COLOR.green}
                            borderRadius={10}
                            justifyContent={"center"}
                            fontSize={27}
                        >
                            <FontAwesomeIcon color={COLOR.white} icon={faWhatsapp} />
                        </Stack>
                    </Link>
                )
            }
            {
                CONTACT?.facebook && (
                    <Link
                        href={CONTACT.facebook}
                    >
                        <Stack
                            width={40}
                            height={40}
                            bgcolor={COLOR.darkBlue}
                            borderRadius={10}
                            justifyContent={"center"}
                            fontSize={27}
                        >
                            <FontAwesomeIcon color={COLOR.white} icon={faFacebookF} />
                        </Stack>
                    </Link>
                )
            }
            {
                CONTACT?.instagram && (
                    <Link
                        href={CONTACT.instagram}
                    >
                        <Stack
                            width={40}
                            height={40}
                            sx={{
                                background: `linear-gradient(45deg,${COLOR.red}, ${COLOR.primaryBlue}, ${COLOR.yellow})`
                            }}
                            bgcolor={COLOR.darkBlue}
                            borderRadius={10}
                            justifyContent={"center"}
                            fontSize={27}
                        >
                            <FontAwesomeIcon color={COLOR.white} icon={faInstagram} />
                        </Stack>
                    </Link>
                )
            }
            {
                CONTACT?.titkok && (
                    <Link
                        href={CONTACT.titkok}
                    >
                        <Stack
                            width={40}
                            height={40}
                            bgcolor={COLOR.black}
                            borderRadius={10}
                            justifyContent={"center"}
                            fontSize={27}
                        >
                            <FontAwesomeIcon
                                color={COLOR.red}
                                icon={faTiktok}
                            />
                        </Stack>
                    </Link>
                )
            }
        </Stack>
    )
}

export default ContactSocialGroup