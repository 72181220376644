import { Button, Input, Stack, Textarea, Typography } from '@mui/joy'
import { COLOR } from '../../constants/constants'
import ContactSocialGroup from '../ContactSocialGroup/ContactSocialGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'


const ContactZone = () => {
    const [showForm, setshowForm] = useState(true);

    return (
        <Stack
            
        >
            <Typography level="h4" color={COLOR.white}>Contactez nous</Typography>

            <ContactSocialGroup
                mt={0}
            />

            <Button
                endDecorator={
                    <FontAwesomeIcon icon={showForm ? faArrowUp : faArrowDown} />
                }
                sx={{
                    p: 0,
                    bgcolor: "transparent",
                    color: COLOR.falseBlack,
                    textDecoration: "underline",
                    ":hover": {
                        bgcolor: "transparent",
                    }
                }}
                onClick={() => setshowForm(!showForm)}
            >
                Soumettre un message
            </Button>

            <form
                style={{
                    display: showForm ? `initial` : `none`
                }}
            >
                <Stack
                    gap={1}
                >
                    <Input
                        fullWidth
                        placeholder='Votre nom et prenom'
                        required
                    />
                    <Input
                        fullWidth
                        placeholder='votre Email'
                    />
                    <Input
                        fullWidth
                        placeholder='Votre numero de telephone'
                        required
                    />
                    <Textarea
                        fullWidth
                        placeholder='Votre message'
                        required
                    />
                    <Button
                        sx={{
                            fontSize: 11
                        }}
                        endDecorator={
                            <FontAwesomeIcon icon={faPaperPlane} />
                        }
                    >
                        Soumettre un message privé
                    </Button>
                </Stack>
            </form>
        </Stack>
    )
}

export default ContactZone