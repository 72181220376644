import { faCarAlt, faCommentDots, faHomeAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import logo from "../assets/images/logo924x272.png"
import lexusWalPaper from "../assets/images/lexus.jpg"
import corolaWallPaper from "../assets/images/corolaWallPaper.webp"
import kiaWallPaper from "../assets/images/kiaWallPaper.webp"
import mercedesWallPaper from "../assets/images/mercedesWallPaper.jpg"
import rangeRoverWallPaper from "../assets/images/rangeRoverWallPaper.webp"
import toyotaLogo from "../assets/images/toyotaLogo.png"
import bmwX6 from "../assets/images/bmwX6.jpg"
import kiaLogo from "../assets/images/kiaLogo.webp"
import mercedesLogo from "../assets/images/mercedesLogo.png"
import BMWLogo from "../assets/images/BMWLogo.svg.png"
import HyundaiLogo from "../assets/images/HyundaiLogo.jpg"
import opelLogo from "../assets/images/opelLogo.jpg"
import rangeRoverFooterback from "../assets/images/rangeRoverFooterback.jpg"

export const IMAGE = {
    defaultImage: logo,
    lexusWalPaper: lexusWalPaper,
    corolaWallPaper: corolaWallPaper,
    kiaWallPaper: kiaWallPaper,
    mercedesWallPaper: mercedesWallPaper,
    rangeRoverWallPaper: rangeRoverWallPaper,
    toyotaLogo: toyotaLogo,
    bmwX6: bmwX6,
    kiaLogo: kiaLogo,
    mercedesLogo: mercedesLogo,
    BMWLogo: BMWLogo,
    HyundaiLogo: HyundaiLogo,
    opelLogo: opelLogo,
    rangeRoverFooterback: rangeRoverFooterback,
}

export const COLOR = {
    primaryBlue: "#0b6df6",
    darkBlue: "#1e4763",
    lightGrey: "#d1d1d5",
    darkGrey: "#6c8899",
    lightblue: "#80d7ff",
    falseBlack: "#041824",
    white: "#FFFFFF",
    black: "#000000",
    green: "#298b50",
    yellow: "#c0ad4a",
    red: "#cd0000",
}

export const CONTACT = {
    tel: [
        "+223 66 03 53 00",
    ],
    email: "contact@karlo-car.com",
    whatsapp: "+22366035300",
    facebook: "facebook.com",
    instagram: "instagram.com",
    titkok: "tiktok.com",
}

export const PAGE_LIST = [
    {
        pageName: "Accueil",
        icon: faHomeAlt,
        link: "/"
    },
    {
        pageName: "Garage",
        icon: faCarAlt,
        link: "/garage"
    },
    {
        pageName: "Contact",
        icon: faCommentDots,
        link: "/contact"
    },
    {
        pageName: "A  propos",
        icon: faInfoCircle,
        link: "/about"
    },
]