import { Avatar, Box, Card, CardCover, Stack, Typography } from "@mui/joy"
import { COLOR, IMAGE } from "../../constants/constants"
import { Button, ButtonGroup, CardMedia } from "@mui/material"
import bmw from "../../assets/videos/bmwX6.mp4"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCarBattery, faCarBurst, faCarSide, faCarTunnel, faCashRegister, faGears, faPooStorm, faStar, faThunderstorm, faUserAstronaut } from "@fortawesome/free-solid-svg-icons"

const BmwX6Zone = () => {
    return (
        <Stack
            direction={"row"}
            gap={2}
            justifyContent={"space-around"}
            flexWrap={"wrap"}
        >

            <Stack
                p={1}
                bgcolor={COLOR.primaryBlue}
                borderRadius={25}
                gap={1}
                width={"58%"}
                minWidth={350}
                maxWidth={600}
                height={"fit-content"}
            >
                <Typography
                    textColor={COLOR.white}
                    level="h2"
                >
                    3 Bonnes raisons de prendre la
                    <Typography
                        textColor={COLOR.white}
                        fontWeight={700}
                        level="h1"
                    > BMW X6</Typography>
                </Typography>
                <CardMedia
                    sx={{
                        width: "100%",
                        borderRadius: "15px"
                    }}
                    src={bmw}
                    controls
                    component={"video"}
                    poster={IMAGE.bmwX6}
                />
            </Stack>

            <Stack
                width={"40%"}
                minWidth={350}
                justifyContent={"space-between"}
                gap={2}
            >
                <Stack
                    gap={3}
                >
                    <Typography
                        textColor={COLOR.white}
                        textAlign={"justify"}
                    >
                        <Avatar
                            sx={{
                                float: "left",
                                mr: 2
                            }}
                        >
                            <FontAwesomeIcon icon={faStar} />
                        </Avatar>
                        <b>Design et présence : </b>
                        Elle est connue pour son design distinctif et sa silhouette imposante de SUV coupé. Idéale pour ceux qui veulent rouler avec style.
                    </Typography>

                    <Typography
                        textColor={COLOR.white}
                        textAlign={"justify"}
                    >
                        <Avatar
                            sx={{
                                float: "left",
                                mr: 2
                            }}
                        >
                            <FontAwesomeIcon icon={faUserAstronaut} />
                        </Avatar>
                        <b>Performances et confort : </b>
                        Elle propose un moteur surpuissant et une conduite dynamique. De plus, elle offre un intérieur luxueux avec des matériaux de haute qualité pour une expérience de conduite agréable.
                    </Typography>

                    <Typography
                        textColor={COLOR.white}
                        textAlign={"justify"}
                    >
                        <Avatar
                            sx={{
                                float: "left",
                                mr: 2
                            }}
                        >
                            <FontAwesomeIcon icon={faGears} />
                        </Avatar>
                        <b>Technologie et sécurité : </b>
                        La X6 est équipée de technologies de pointe, y compris des systèmes d'infodivertissement sophistiqués, des aides à la conduite comme le régulateur de vitesse adaptatif et l'assistance au maintien de voie, ainsi que des fonctionnalités de sécurité avancées pour protéger les occupants.
                    </Typography>
                </Stack>

                <ButtonGroup
                    fullWidth
                    sx={{
                        "& button": {
                            borderRadius: 20,
                            bgcolor: COLOR.white,
                            fontWeight: 700,
                            fontSize: 15,
                            color: COLOR.darkBlue,
                            borderColor: COLOR.darkBlue,
                        },
                    }}
                >
                    <Button>Louer Une BMW X6</Button>
                    <Button>Acheter Une BMW X6</Button>
                </ButtonGroup>
            </Stack>
        </Stack >
    )
}

export default BmwX6Zone