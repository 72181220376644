import { Container, Stack } from "@mui/joy"
import Welcome from "./Welcome"
import PopularZone from "./PopularZone"
import PrixbasZone from "./PrixbasZone"
import MarqueZone from "./MarqueZone"
import BmwX6Zone from "./BmwX6Zone"

const Accueil = () => {
  return (
    <>
      <Welcome />
      <Container
        sx={{
          pb: 5,
          mt: 5
        }}
      >
        <Stack
          gap={6}
        >
          <PopularZone />

          <BmwX6Zone/>

          <MarqueZone />

          <PrixbasZone />
        </Stack>
      </Container>
    </>
  )
}

export default Accueil