import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, LinearProgress, Link, Stack, Typography } from "@mui/joy"
import { COLOR } from "../../constants/constants"

const PageLink = ({ name, icon, link, is_active = false }) => {
    return (
        <Link
            href={link}
        >
            <Box>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    fontWeight={700}
                    gap={1}
                    color={!is_active ? COLOR.falseBlack : undefined}
                >
                    <FontAwesomeIcon icon={icon} />
                    <Typography>{name}</Typography>
                </Stack>
                {is_active && <LinearProgress />}
            </Box>
        </Link>
    )
}

export default PageLink