import { Card, CardContent, CardCover, Link, Typography } from "@mui/joy"
import { COLOR, IMAGE } from "../../constants/constants"

const MarqueCard = ({img}) => {
    return (
        <Link
            sx={{
                textDecoration: "none !!important",
            }}
        >
            <Card
                sx={{
                    width: 200,
                    height: 200,
                    borderRadius: 15,
                    p: 1,
                    transition: `all .3s`,
                    border: `1px solid white`,
                    ":hover": {
                        boxShadow: `0px 0px 15px ${COLOR.lightblue}`,
                        transform: `scale(1.03)`
                    }
                }}
            >
                <CardCover>
                    <img src={img} />
                </CardCover>
            </Card>
        </Link>
    )
}

export default MarqueCard