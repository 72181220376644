import { Route, Routes } from 'react-router-dom'
import Accueil from '../../Pages/accueil'

const Router = () => {
  return (
    <Routes >
      <Route path="/" element={<Accueil />} />
    </Routes>
  )
}

export default Router