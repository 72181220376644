import { Avatar, Button, Card, CardContent, CardCover, Link, Stack, Typography } from "@mui/joy"
import { COLOR, IMAGE } from "../../constants/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCarAlt } from "@fortawesome/free-solid-svg-icons"

const VoitureCard = ({ img, prix, nom, marqueImage, isALouer = false }) => {
    return (
        <Link
            sx={{
                textDecoration: "none !!important",
            }}
        >
            <Card
                sx={{
                    width: 250,
                    height: 250,
                    borderRadius: 15,
                    p: 1,
                    transition: `all .3s`,
                    border: `1px solid white`,
                    ":hover": {
                        boxShadow: `0px 0px 15px ${COLOR.lightblue}`,
                        transform: `scale(1.03)`
                    }
                }}
            >
                <CardCover>
                    <img src={img || IMAGE.lexusWalPaper} />
                </CardCover>
                <CardContent
                    sx={{
                        direction: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                    >
                        <Card
                            sx={{
                                borderRadius: "10px",
                                width: 35,
                                height: 35,
                                p: 0,
                            }}

                        >
                            <CardCover>
                                {
                                    marqueImage
                                        ? (
                                            <img
                                                src={marqueImage}
                                                sx={{
                                                    width: "100%",
                                                }}
                                            />
                                        )
                                        : (
                                            <Avatar
                                                variant="solid"
                                            >
                                                <FontAwesomeIcon
                                                sx={{
                                                    color:COLOR.falseBlack
                                                }}
                                                    icon={faCarAlt}
                                                />
                                            </Avatar>
                                        )
                                }
                            </CardCover>

                        </Card>

                        <Button
                            sx={{
                                p: 1
                            }}
                        >{prix || `15.000.000`} FCFA {isALouer && `/ Jours`}</Button>
                    </Stack>
                    <Link
                        sx={{
                            bgcolor: COLOR.primaryBlue,
                            p: 1,
                            borderRadius: 10,
                            color: COLOR.white,
                        }}
                    >
                        <Typography
                            textAlign={"center"}
                            width={"100%"}
                            fontWeight="bold"
                        >
                            {nom || `Lexus`}
                        </Typography>
                    </Link>
                </CardContent>
            </Card>
        </Link>
    )
}

export default VoitureCard