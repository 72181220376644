import { Stack } from "@mui/joy"
import MarqueCard from "../../components/MarqueCard/MarqueCard"
import { IMAGE } from "../../constants/constants"

const MarqueZone = () => {
  return (
    <Stack
      height={"fit-content"}
      py={2}
      gap={2}
      direction={"row"}
      sx={{
        overflowX: "scroll",
      }}
    >
      <MarqueCard
        img={IMAGE.toyotaLogo}
      />
      <MarqueCard
        img={IMAGE.kiaLogo}
      />
      <MarqueCard
        img={IMAGE.mercedesLogo}
      />
      <MarqueCard
        img={IMAGE.BMWLogo}
      />
      <MarqueCard
        img={IMAGE.HyundaiLogo}
      />
      <MarqueCard
        img={IMAGE.opelLogo}
      />
    </Stack>
  )
}

export default MarqueZone