import { Button, Card, CardContent, CardCover, Input, Stack, Switch, Typography } from '@mui/joy'
import { COLOR, IMAGE } from '../../constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { useRef, useState } from 'react'

const categorieList = [
    {
        img: IMAGE.rangeRoverWallPaper,
        text: "Range Rover"
    },
    {
        img: IMAGE.lexusWalPaper,
        text: "Lexus"
    },
    {
        img: IMAGE.corolaWallPaper,
        text: "Toyota"
    },
    {
        img: IMAGE.kiaWallPaper,
        text: "Kia"
    },
    {
        img: IMAGE.mercedesWallPaper,
        text: "Mercedes"
    },
]

const CardCategorie = ({ img, categorie, isBig = false }) => {
    return (
        <Card
            sx={{
                width: "100%",
                borderRadius: 0,
                // borderColor: COLOR.falseBlack,
                filter: `grayscale(${isBig ? 0 : 1})`,
                borderTop: 0,
                borderBottom: 0,
                transform: `scale(${isBig ? 1.4 : 1})`,
                transition: `all 0.2s`,
                ":hover": {
                    minWidth: 250,
                    filter: `grayscale(0)`,
                },
                "& button": {
                    display: "none"
                },
                "&:hover button": {
                    display: "initial"
                }
            }}
        >
            <CardCover>
                <img
                    src={img || IMAGE.kiaWallPaper}
                />
            </CardCover>
            <CardCover sx={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.945), rgba(255, 255, 255, 0))`,
                opacity: 1
            }} />
            <CardContent
                sx={{
                    justifyContent: "center",

                }}
            >
                <Button
                    sx={{
                        gap: 2,
                        width: "fit-content",
                        alignSelf: "center"
                    }}
                >
                    {categorie || "A louer"}
                </Button>
            </CardContent>
        </Card>
    )
}

const Welcome = () => {
    const [cardIndex, setcardIndex] = useState(0);

    const inputRef = useRef < HTMLInputElement | null > (null);

    setTimeout(() => {
        setcardIndex(cardIndex > 4 ? 0 : cardIndex + 1);
    }, [3000]);

    return (
        <Card
            sx={{
                width: "100vw",
                height: "100vh",
                minHeight: "100vh",
                bgcolor: "transparent",
                p: 0,
                border: 0,
                borderRadius: 0,
                overflow: "hidden"
            }}
        >
            <CardContent
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 3,
                    bgcolor: COLOR.falseBlack,
                    width: "fit-content",
                    height: "fit-content",
                    position: "absolute",
                    bottom: 20,
                    p: 1,
                    borderRadius: 15,
                    ml: "50%",
                    transform: "translateX(-50%)"
                }}
            >
                <Stack
                    gap={2}
                    alignItems="center"
                    height={"fit-content"}
                >
                    <Input
                        startDecorator={
                            <Button
                                sx={{
                                    borderRadius: 8,
                                    height: "80%"
                                }}
                            >Votre budget</Button>
                        }
                        endDecorator={
                            <Typography
                                level='body-sm'
                            >FCFA</Typography>
                        }
                        sx={{
                            borderRadius: 10,
                            height: 50,
                            fontSize: 22,
                            width: 300,
                            fontWeight: "bold"
                        }}
                        slotProps={{
                            input: {
                                ref: inputRef,
                                min: 0,
                                step: 1000,
                            },
                        }}
                        type='number'
                    />
                    <Stack
                        direction={"row"}
                        gap={2}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={1}
                        >
                            <Typography
                                textColor={COLOR.white}
                            >A vendre</Typography>
                            <Switch
                                sx={{
                                    // width: 100
                                }}
                                size='lg'
                                variant='plain'
                            />
                            <Typography
                                textColor={COLOR.white}
                            >A Louer</Typography>
                        </Stack>
                        <Button
                            sx={{
                                bgcolor: COLOR.primaryBlue
                            }}
                        >Search</Button>
                    </Stack>
                </Stack>
            </CardContent>

            <CardContent
                sx={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <Stack
                    height={"100%"}
                    direction={"row"}
                    sx={{
                    }}
                >
                    {
                        categorieList.map((value, index) => (
                            <CardCategorie
                                img={value.img}
                                categorie={value.text}
                                key={index}
                                isBig={cardIndex === index}
                            />
                        ))
                    }
                </Stack>
            </CardContent>
            <CardCover sx={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.945), rgba(255, 255, 255, 0))`,
                opacity: 1
            }} />
        </Card >
    )
}

export default Welcome