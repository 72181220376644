import { Card, Stack } from '@mui/joy'
import { COLOR } from '../../constants/constants'
import ContactZone from './ContactZone'
import MapZone from './MapZone'
import PartenaireZone from './PartenaireZone'


const Footer = () => {
    return (
        <Stack
            direction="row"
            flexWrap={"wrap"}
            gap={5}
            sx={{
                p: 1,
                background: `linear-gradient(45deg, ${COLOR.lightblue}, ${COLOR.darkBlue})`,
                "& > *": {
                    border: 0,
                    color: COLOR.falseBlack,
                    gap: 2,
                    width: window.innerWidth < 900 ? "100%" : 200,
                    maxWidth: window.innerWidth < 900 ? "100%" : 200,
                },
            }}
        >
            <ContactZone />

            <MapZone />

            <PartenaireZone />
        </Stack>
    )
}

export default Footer