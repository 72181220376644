import { Card, CardCover, Stack } from "@mui/joy"
import { IMAGE } from "../../constants/constants"
import NavBar from "../NavBar"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

const Header = () => {
    const [isOpen, setisOpen] = useState(false)
    return (
        <>
            <Stack
                direction={"row"}
                alignItems={"center"}
                p={1}
                justifyContent={"space-between"}
                px={3}
                position={"fixed"}
                top={0}
                left={0}
                right={0}
                zIndex={3}
            >
                <img
                    src={IMAGE.defaultImage}
                    style={{
                        width: 100,
                        maxHeight: 50,
                    }}
                />

                <FontAwesomeIcon
                    icon={faBars}
                    onClick={() => setisOpen(true)}
                    fontSize={30}
                    style={{
                        cursor: "pointer"
                    }}
                />

            </Stack >
            <NavBar is_open={isOpen} setisopen={setisOpen} />
        </>
    )
}

export default Header