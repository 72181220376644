import { Link, Stack, Typography } from "@mui/joy"
import { COLOR, IMAGE } from "../../constants/constants"
import VoitureCard from "../../components/VoitureCard/VoitureCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { Avatar } from "@mui/material"

const PopularZone = () => {
    return (
        <Stack
            gap={5}
        >
            <Typography level="h1" textColor={COLOR.white}>
                Decouvrez nos voitures les plus populaires
            </Typography>

            <Stack
                gap={4}
                direction={"row"}
                flexWrap={"wrap"}
                justifyContent={"center"}
            >
                <VoitureCard
                    img={IMAGE.lexusWalPaper}
                    nom={"Lexus LX 570"}
                    isALouer={true}
                    prix={`50.000`}
                />
                <VoitureCard
                    img={IMAGE.corolaWallPaper}
                    nom={"Corolla SE"}
                    prix={`8.500.000`}
                    marqueImage={IMAGE.toyotaLogo}
                />
                <VoitureCard
                    img={IMAGE.kiaWallPaper}
                    nom={"Kia Sportage"}
                />
                <VoitureCard
                    img={IMAGE.mercedesWallPaper}
                    nom={"AMG C63  Coupé"}
                    isALouer={true}
                    prix={`75.000`}
                />
                <VoitureCard
                    img={IMAGE.rangeRoverWallPaper}
                    nom={"Range rover Evoque 2014"}
                    isALouer={true}
                    prix={`75.000`}
                />
                <VoitureCard
                    img={IMAGE.lexusWalPaper}
                    nom={"Lexus LX 570"}
                    isALouer={true}
                    prix={`50.000`}
                />
                <VoitureCard
                    img={IMAGE.corolaWallPaper}
                    nom={"Corolla SE"}
                    prix={`8.500.000`}
                    marqueImage={IMAGE.toyotaLogo}
                />
                <VoitureCard
                    img={IMAGE.kiaWallPaper}
                    nom={"Kia Sportage"}
                />
                <VoitureCard
                    img={IMAGE.mercedesWallPaper}
                    nom={"AMG C63  Coupé"}
                    isALouer={true}
                    prix={`75.000`}
                />
                <VoitureCard
                    img={IMAGE.rangeRoverWallPaper}
                    nom={"Range rover Evoque 2014"}
                    isALouer={true}
                    prix={`75.000`}
                />
                <VoitureCard
                    img={IMAGE.lexusWalPaper}
                    nom={"Lexus LX 570"}
                    isALouer={true}
                    prix={`50.000`}
                />
                <VoitureCard
                    img={IMAGE.corolaWallPaper}
                    nom={"Corolla SE"}
                    prix={`8.500.000`}
                    marqueImage={IMAGE.toyotaLogo}
                />
            </Stack>
            <Link
                sx={{
                    alignSelf: "flex-end",
                }}
            >
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    bgcolor={COLOR.white}
                    borderRadius={10}
                >
                    <Typography
                        // textColor={"common.white"}
                        fontWeight={700}
                    >Voir plus</Typography>
                    <Avatar
                        variant="rounded"
                        sx={{
                            bgcolor: COLOR.lightblue,
                        }}
                    >
                        <FontAwesomeIcon icon={faFilter} />
                    </Avatar>
                </Stack>
            </Link>
        </Stack>
    )
}

export default PopularZone